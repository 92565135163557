import React, { useEffect } from "react";
import Update from "./Update";
import ProcessingTime from "./processing-time/ProcessingTime";
import DailyStats from "./UserStats/DailyStats";
import { format } from "date-fns";
import { myClock } from "./utils";
import { getPosts } from "./requests";
import { getApps } from "../apps/request";
import { getScopesData } from "../scopes";
import { getEtsyShop } from "../Stores/request";
import { getWidgetsArray } from "./widgetConfig";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Container, Typography } from "@mui/material";
import { setModalState } from "src/redux/actions/modalActions";
import { AppWidgetSummary } from "../../sections/dashboard/app";
import { setEtsyOrderList } from "src/redux/actions/orderActions";
import { getOrderStats } from "../orders/requests/icp/orderStats";
import { getPrintProviders } from "../users/requests";
import { getTaxDocument } from "../settings/document/request";
import { getCatalogsList } from "../catalog/request";

export default function DashboardAppPage() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const apps = useSelector((state) => state.oAuth.apps);
  const scopes = useSelector((state) => state.oAuth.scopes);
  const userType = useSelector((state) => state.user.user.type);
  const orderStats = useSelector((state) => state.orders.orderStats);

  const firstName = user?.name?.split(" ")[0];
  const widgets = getWidgetsArray(userType, scopes, apps, orderStats);

  useEffect(() => {
    if (userType === "Developer") {
      getApps(dispatch);
      getScopesData(dispatch);
    } else {
      dispatch(setEtsyOrderList([]));
      getOrderStats(dispatch, userType);
      getPosts(dispatch);
      getCatalogsList(dispatch);
      if (userType === "Seller") {
        getEtsyShop(dispatch);
        getTaxDocument(dispatch);
      } else {
        getPrintProviders(dispatch, 1, 100);
      }
    }
    dispatch(setModalState(undefined));
    myClock();
  }, [dispatch, userType]);

  return (
    <Container maxWidth="2xl" sx={{ margin: "0px", padding: "0px" }}>
      {/* {userType === "Seller" && !notificationIsSeen && <PermitNotification />} */}
      <Grid container spacing={3} sx={{ mb: 5 }} alignItems={"center"}>
        <Grid item xs={6}>
          <Typography variant="h4">Hi {firstName}, Welcome</Typography>
        </Grid>

        <Grid item xs={6} textAlign={"end"}>
          <Typography variant="p">
            {format(new Date(), "MMM dd,yyyy")} <span id="time"></span>
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        {widgets?.map((widget, index) => (
          <Grid key={index} item xs={12} sm={8} md={6} xl={3}>
            <AppWidgetSummary
              sx={{ height: "100%" }}
              title={widget.title}
              total={widget.total}
              card_color={widget.cardColor}
              icon_color={widget.iconColor}
              icon={widget.icon}
            />
          </Grid>
        ))}
      </Grid>

      {userType === "Developer" ? (
        ""
      ) : (
        <>
          {userType === "Super Admin" && <DailyStats />}
          <ProcessingTime />
          <Update />
        </>
      )}
    </Container>
  );
}
