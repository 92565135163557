import { useEffect, useState } from "react";
import {
  Stack,
  Paper,
  Container,
  Typography,
  Button,
  Box,
  Tab,
} from "@mui/material";
import UserTable from "./UserTable";
import AllUserStats from "./AllUserStats";
import Iconify from "../../components/iconify";
import CreateUsers from "src/sections/modals/user/createUsers";
import TotalProductsBarChart from "src/sections/modals/user/TotalProductsBarChart";
import TotalAdminRevenueChart from "src/sections/modals/user/TotalAdminRevenueChart";
import { isValidPagination } from "./utils";
import { getAllUserStats, getPrintProviders, getUsers } from "./requests";
import { useDispatch, useSelector } from "react-redux";
import { fShortenNumber } from "src/utils/formatNumber";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { setModalState } from "src/redux/actions/modalActions";
import {
  setSelectedFilter,
  setUserPagination,
} from "src/redux/actions/userActions";
import FilterDropdown from "src/components/filter-dropdown";

export default function UserPage() {
  const dispatch = useDispatch();
  const [value, setValue] = useState("1");
  const [activeUsers, setActiveUsers] = useState([]);
  const [inActiveUsers, setInActiveUsers] = useState([]);

  const userList = useSelector((state) => state.user.userList);
  const userType = useSelector((state) => state.user.user.type);
  const usersMeta = useSelector((state) => state.user.userMeta);
  const userPagination = useSelector(
    (state) => state.user.userPagination ?? [1, 25]
  );
  const selectedFilter = useSelector((state) => state.user.selectedFilter);
  const filterOptions = ["All Users", "Sellers", "Print Providers"];

  useEffect(() => {
    let page = userPagination ? userPagination?.page : 1;
    let rowsPerPage = userPagination ? userPagination?.rowsPerPage : 25;

    getAllUserStats(dispatch);
    getUsers(dispatch, page, rowsPerPage, selectedFilter);
    dispatch(setModalState(undefined));

    if (!isValidPagination(userPagination)) {
      dispatch(setUserPagination(1, 25));
    }
  }, [dispatch, userPagination, selectedFilter]);

  useEffect(() => {
    if (userList) {
      const activeUsers = userList?.filter((item) => item.status === 1);
      const inActiveUsers = userList?.filter((item) => item.status === 0);

      setActiveUsers(activeUsers);
      setInActiveUsers(inActiveUsers);
    }
  }, [userList]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFilterChange = (option) => {
    dispatch(setSelectedFilter(option));
  };

  return (
    <Container maxWidth="2xl" sx={{ margin: "0px", padding: "0px" }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          Users
        </Typography>

        <Button
          variant={"contained"}
          startIcon={<Iconify icon="eva:plus-fill" />}
          onClick={() => dispatch(setModalState(<CreateUsers />, true))}
        >
          New User
        </Button>
      </Stack>

      {userType === "Super Admin" && <AllUserStats />}

      <Paper sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: 1,
              borderColor: "divider",
              backgroundColor: "white",
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              // wrapped
              variant="scrollable"
              allowScrollButtonsMobile
            >
              <Tab
                label={`Active Users (${fShortenNumber(
                  usersMeta?.activeUsers
                )})`}
                value="1"
              />
              <Tab
                label={`Inactive Users (${fShortenNumber(
                  usersMeta?.inActiveUsers
                )})`}
                value="2"
              />
              <Tab label={`Total products sold per month`} value="3" />
              <Tab label={`Total admin revenue per month`} value="4" />
            </TabList>

            <FilterDropdown
              options={filterOptions}
              selectedOption={selectedFilter}
              onFilterChange={handleFilterChange}
            />
          </Box>

          <TabPanel sx={{ margin: "0px", padding: "0px" }} value="1">
            <UserTable userList={activeUsers} userStatus={"active"} />
          </TabPanel>

          <TabPanel sx={{ margin: "0px", padding: "0px" }} value="2">
            <UserTable userList={inActiveUsers} userStatus={"inactive"} />
          </TabPanel>
          <TabPanel sx={{ margin: "0px", padding: "0px" }} value="3">
            <TotalProductsBarChart />
          </TabPanel>
          <TabPanel sx={{ margin: "0px", padding: "0px" }} value="4">
            <TotalAdminRevenueChart />
          </TabPanel>
        </TabContext>
      </Paper>
    </Container>
  );
}
