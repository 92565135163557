import request from "src/utils/request";
import { getOrderStats } from "./orderStats";
import { getOrdersByStatus } from "./getSellerOrders";
import { filterOrdersByCatalog } from "./getAdminOrders";
import { loadingBtnAction } from "src/redux/actions/userActions";
import { getPrintProvidersOrders } from "./getPrintProvidersOrders";
import { setModalState } from "src/redux/actions/modalActions";

export const setOrderTabName = (userType, tabValue) => {
  if (userType === "Seller") {
    return tabValue === "1"
      ? "all"
      : tabValue === "2"
      ? "on_hold"
      : tabValue === "3"
      ? "in_production"
      : tabValue === "4"
      ? "awaiting_shipment"
      : tabValue === "5"
      ? "fulfilled"
      : tabValue === "6"
      ? "refunded"
      : "";
  } else {
    return tabValue === "1"
      ? "all"
      : tabValue === "2"
      ? "awaiting_approval"
      : tabValue === "3"
      ? "awaiting_shipment"
      : tabValue === "4"
      ? "shipped"
      : tabValue === "5"
      ? "refunded"
      : "";
  }
};

export const getAllOrdersList = (
  dispatch,
  userType,
  page,
  rowsPerPage,
  tabValue
) => {
  let catalog_id = sessionStorage.getItem("selectedCatalogID");
  let tabName = setOrderTabName(userType, tabValue);

  if (userType === "Seller") {
    getOrdersByStatus(dispatch, tabName, page, rowsPerPage);
  } else if (userType === "PrintProvider") {
    getPrintProvidersOrders(dispatch, tabName, page, rowsPerPage);
  } else {
    filterOrdersByCatalog(
      dispatch,
      userType,
      catalog_id && catalog_id !== "undefined" ? [catalog_id] : [],
      page,
      rowsPerPage,
      tabValue
    );
  }
  getOrderStats(dispatch, userType);
};

export const onUnlock = async (
  dispatch,
  userType,
  page,
  rowsPerPage,
  tabValue,
  id
) => {
  dispatch(loadingBtnAction(true));
  try {
    const res = await request.post(`/orders/toggle/lock/${id}`);
    if (res) {
      getAllOrdersList(dispatch, userType, page, rowsPerPage, tabValue);
      dispatch(setModalState(undefined));
      dispatch(loadingBtnAction(false));
    }
  } catch (e) {
    dispatch(loadingBtnAction(false));
  }
};
